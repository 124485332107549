@import '../../../sass/base';

.snapshot-container {
    .go-back {
        display: flex;
        align-items: center;
        padding-left: 10%;
        svg {
            font-size: 16px;
            padding-right: 8px;
        }
        padding-bottom: 32px;
        cursor: pointer;
    }

    .snapshot-wrapper {
        display: flex;
        justify-content: center;
        padding-bottom: 100px;
        .snapshot {
            width: 640px;
            min-height: 880px;
            border: $primaryBorder;
            border-radius: 20px;
            &-header {
                width: 100%;
                height: 240px;
                background-color: #022119;
                border-radius: 20px 20px 0 0;
                position: relative;
                .user-avatar-wrapper {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 160px;
                    left: 36px;
                    .user-avatar {
                    border: 10px solid #FFF
                    }
                    .user-avatar, .user-avatar img {
                    width: 160px;
                    height: 160px;
                    }
                }
            }
            &-content {
                padding: 32px 48px;
                display: grid;
                button {
                    justify-self: flex-end;
                }
                h5 {
                    font-weight: bold;
                    padding: 60px 0 16px 0;
                }
                .social {
                    padding: 32px 0;
                    img {
                        margin-right: 8px;
                    }
                }
                .inspire-title {
                    font-weight: bold;
                }
            }
        }
    }
}