@import '../../sass/base';

.footer {
    padding-bottom: 100px;
    .logo-red {
        margin-top: 16px;
        justify-self: flex-start;
        margin-bottom: 24px;
    }
    .logout {
        margin-top: 15px;
        justify-self: flex-start;
        position: absolute;
        right: 10%;
        cursor: pointer;
        // color: $primaryColor;
    }
    h5 {
        margin-bottom: 8px;
        font-size: 15px;
        font-weight: 600;
    }
    .footer-content {
        display: flex;
        justify-content: center;
    }
    .footer-link {
        color: $footerGrey;
        font-size: 13px;
        @media (max-width: 830px) {
            display: none;
        }
    }
    .footer-list {
        display: flex;
        width: 100%;
        justify-content: space-between;
        @media (max-width: 830px) {
            padding-top:60px;
            padding-bottom:20px;
            display: flex;
            width: 100%;
            flex-direction: column;
        }
    }
    ul {
        @media (max-width: 830px) {
            width: 100%;
            min-width: auto;
            display: contents;
        }
        list-style: none;
        &:last-of-type {
            display: grid;
            @media(max-width: 830px) {
                display: contents;
            }
        }
    }
    margin: auto;
    width: 80%;
    border-top: 1px solid #d8d8d8;
    &-copyright {
        display: flex;
        height: 100%;
        padding-top: 32px;
        // width: 190px;
        @media(max-width: 830px) {
            padding-top: 32px;
        }
    }
    .footer-social {
        margin-top: auto;
        width: 72px;
        @media(max-width: 830px) {
            margin-top: 0;
            display: flex;
        }
        .twitter-logo {
            padding-left: 8px;
        }
    }
    .copyright {
        &-bottom {
            display: none;
        }
        @media(max-width: 830px) {
            display: none;
            &-bottom {
                display: block;
                padding-top: 32px;
            }
        }
    }
}