@import '../../../sass/base';

.dashboard {
    max-width: 416px;
    margin-bottom:50px;
    position: relative;
    z-index: 2;
    @media (max-width: 830px) {
        max-width: 100%;
    }
    .dashboard-progress {
        display: flex;
        padding: 50px 0;
        .gauge {
            padding: 16px;
        }
    }
    .dashboard-gauge-caption {
        line-height: 1em;
    }
    h4 {
        font-weight: bold;
        padding-bottom: 16px;
    }
    .dashboard-chip {
        border: none;
        width: 100%;
        justify-content: flex-start;
        span {
            white-space: break-spaces;
        }
    }
    .MuiChip-icon {
        color: inherit;
    }
    .dashboard-items {
        display: flex;
        .column {
            max-width: 50%;
        }
    }
    .primary {
        color: $primaryColor;
    }
    .secondary {
        color: $secondaryColor;
    }
    .reminder {
        margin: 50px 0 100px 0;
        span {
            font-weight: bold;
        }
    }
    .button-wrapper {
        margin-top:20px;
        margin-bottom:60px;
    }
}