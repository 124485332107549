@import '../../sass/base';

.registration {
    .logo-container {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 50px 0;
      .logo {
        width: 156px;
        justify-self: center;
      }
    }
  .step1, .registration-step {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    // input[name="bio"] {
    //   height: 384px;
    // }
    h5 {
      font-weight: 800;
    }
    .registration-subtitle {
      padding-bottom: 50px;
    }
    &-content {
      display: grid;
      text-align: center;
      .separator {
        margin: $defaultPadding 0;
        display: flex;
        hr {
          border-bottom: $primaryBorder;
          border-left: none;
          border-right: none;
          border-top: none;
          width: 75px;
        }
        span {
          padding: 0 $defaultPadding;
          font-weight: 800;
        }
      }
      .option-button {
        cursor: pointer;
        padding: 24px;
        border-radius: $borderRadius;
        border: 2px solid $borderColor;
        font-weight: 600;
        &:hover, &--selected {
          border: 2px solid #4eadf2;
        }
      }
      .continue-button {
        margin: 32px 0 50px 0;
      }
    }
  }

  .step2 {
    .registration-content {
      width: 375px;
      h5 {
        text-align: center;
      }
      h6 {
        font-weight: 600;
      }
      form {
        display: grid;
        padding: 16px;
      }
    }
  }
}

.education-modal {
  h5 {
    font-weight: 800;
  }
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
}

.education-dialog {
  .dialog-title {
    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    h6 {
      font-weight: 800;
    }
  }
  .button-wrapper {
    padding: 50px 0 16px 0;
  }
  .dialog-close {
    position: absolute;
    right: 4px;
    top: 4px;
  }
}

.education-summary {
  display: grid;
  .education-title {
    padding-bottom: 0;
    margin: 8px 0;
  }
  .education-item {
    color: $footerGrey;
  }
  .education-item-divider {
    margin: 16px 0;
  }
  .button-wrapper {
    width: 100%;
    align-items: center;
  }
}