.create-application {
    width: 416px;
    padding-left: 10%;
    position: relative;
    z-index: 2;
    @media (max-width: 830px) {
        width: auto;
        padding: 0 10%;
    }
    .button-wrapper {
        padding: 44px 0 100px 0;
    }
    h6 {
        font-weight: 800;
    }
    .registration-subtitle {
        padding-bottom: 50px;
    }
    .go-back {
        display: flex;
        align-items: center;
        svg {
            font-size: 16px;
            padding-right: 8px;
        }
        padding-bottom: 32px;
        cursor: pointer;
    }
}