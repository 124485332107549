@import '../../../sass/base';

.agent-profile-content {
    max-width: 416px;
    margin-bottom:50px;
    margin: auto;
    @media (max-width: 830px) {
        max-width: 100%;
    }
    h5 {
        font-weight: bold;
        padding-bottom: 16px;
    }
    .button-wrapper {
        margin-top:20px;
        margin-bottom:60px;
    }
}

.agent-profile {
  margin: 0 10%;
  .go-back {
    display: flex;
    align-items: center;
    svg {
        font-size: 16px;
        padding-right: 8px;
    }
    padding-bottom: 32px;
    cursor: pointer;
  }
}