body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  // font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  background-color: #fff;
  height: 100%;
  width: 100%;
}

.main-content {
  // background: linear-gradient(180deg, rgba(79, 173, 242, 0.1) 0%, rgba(79, 173, 242, 0) 100%);
  // background-image: url('./assets/main-background.avif');
  // background-size: 100% 60vh;
  // background-repeat: no-repeat;
  // background: url('./assets/circles.png') no-repeat,url('./assets/main-background.avif') no-repeat;
  background: url('./assets/circles.png') no-repeat,url('./assets/bg-rainbow.jpeg') no-repeat;
  background-position: top,top;
  background-size: 1200px 1200px,cover;
  min-height: 1500px;
}

.page-content {
  max-width: 1152px;
  margin: auto;
}

.bold {
  font-weight: 700 !important;
}