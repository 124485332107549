@import '../../../sass/base';

.user-menu {
    ul li {
        color: $primaryDarkColor;
        svg {
            padding-right: $defaultPadding;
        }
    }
    min-width: auto;
}
.user-menu-button {
    color: $primaryDarkColor !important;
    justify-self: flex-end;
    font-weight: normal !important;
    svg {
        padding: 0 8px;
    }
}
.user-avatar{
    height: 32px;
    width: 32px;
    min-height: 32px;
    min-width: 32px;
    border: $primaryBorder;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    img {
    //   max-height: 32px;
      width: 32px;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 830px) {
    .user-dropdown {
        display: none !important;
    }
  }

  .user-dropdown {
    width: 240px;
    display: flex;
    justify-content: flex-end;
    // margin-left: auto;
    .hidden {
        visibility: hidden;
    }
    &-username {
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
    }
  }