@import '../../../sass/base';

.profile-upload {
  padding: $defaultPadding 0;
  display: flex;
  .profile-upload-preview{
    height: 128px;
    width: 128px;
    border: $primaryBorder;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    img {
      // min-height: 128px;
      min-width: 128px;
      height: 100%;
      object-fit: cover;
    }
  }
  .profile-upload-actions {
    button {
      display: flex;
      margin: $defaultPadding;
    }
  }
}

.social {
  padding-top:50px;
}

.profile {
  h4 {
    font-weight: bold;
    padding-bottom: 16px;
  }
}

.tsandcs {
  font-weight: bold;
  color: $primaryColor;
}

.acceptTermsError {
  position: absolute;
  padding-top: 56px;
  padding-left: 8px;
}

// .MuiDivider-root {
//   margin: 50px 0px !important;
// }