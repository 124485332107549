@import '../../../sass/base';

.dragDrop-container {
    width: 100%;
    display: grid;
    .dragDrop-item-container {
        border: $primaryBorder;
        border-radius: $borderRadius;
        padding: 10px;
        margin-top: 16px;
    }
}