// Colors
$primaryColor: #4FADF2;
$primaryDarkColor: #012D6C;
$secondaryColor: #e5e5e5;
$buttonGreen: #337347;
$error: #F55D4F;

$primaryText: #000;
$contrastText: #fff;

$borderColor: rgba(0, 0, 0, 0.23);
$footerGrey: #65666a;

// Border
$borderRadius: 8px;
$primaryBorder: 1px solid $borderColor;

// Padding
$defaultPadding: 16px;
