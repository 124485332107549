@import '../../sass/base';

.shortlisting{
    min-width: 416px;
    max-width: 416px;
    position: relative;
    @media (max-width: 830px) {
        max-width: 100%;
        min-width: auto;
    }
    h4 {
        font-weight: bold;
        padding-bottom: 16px;
    }
    &-subtitle {
        padding-bottom: 32px;
    }
    h5 {
        font-weight: bold;
        padding-bottom: $defaultPadding;
    }
    .shortlist {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 32px;
        .university {
            width: 100%;
            padding: 8px 8px 0 8px;
            display: inline-flex;
            justify-content: space-between;
            label {
                font-weight: normal;
            }
        }
    }

    .compare-container {
        padding-bottom: 50px;
        .MuiIconButton-root {
            top: 0 !important;
        }
        h5 {
            font-weight: bold;
            padding-bottom: 32px;
        }

        .compare-wrapper {
            h5 {
                text-align: center;
                width: 60%;
                font-size: 16px;
                overflow-wrap: break-word;
                margin: auto;
            }
            .compare {
                width: 100%;
                border-radius: 20px;
                display: inline-block;
                h5 {
                    font-weight: bold;
                    padding: 16px 0;
                }
                table {
                    border-spacing: unset;
                    border: $primaryBorder;
                    border-radius: 10px;
                    width: 100%;
                    margin-top: $defaultPadding;
                    td {
                        border-bottom: $primaryBorder;
                        padding: 16px;
                        text-align: left;
                    }
                    tr:last-child {
                        td {
                            border-bottom: unset;
                        }
                    }
                }
                .compare-university-photo {
                    padding: 0;
                    height: 250px;
                    position: relative;
                    img {
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                        height: 100%;
                        object-fit: cover;
                        width: 100%;
                    }
                }
            }
        }
    }
}

#alert-dialog-description span {
    font-weight: bold;
}