@import '../../sass/base';

.account-settings {
    max-width: 416px;
    position: relative;
    z-index: 2;
    @media (max-width: 830px) {
        max-width: 100%;
    }
    h4 {
        font-weight: bold;
        padding-bottom: 16px;
    }
}

.account-settings-standalone {
    padding: 0 10% 50px 10%;
    .account-settings {
        max-width: 416px;
        margin: auto;
        @media (max-width: 830px) {
            max-width: 100%;
        }
    }
    .go-back {
        display: flex;
        align-items: center;
        svg {
            font-size: 16px;
            padding-right: 8px;
        }
        padding-bottom: 32px;
        cursor: pointer;
    }
}
