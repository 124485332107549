@import '../../sass/base';

.header-wrapper {
  display: flex;
  justify-content: center;
  @media (max-width: 830px) {
    flex-direction: column;
  }
}
.header {
    padding: 69px 10%;
    // padding-left:10%;
    display: inline-flex;
    align-items: center;
    width: 1152px;
    justify-content: flex-start;
    @media (max-width: 830px) {
      justify-content: space-between;
      width: 80%;
    }
    .logo-container {
    width: fit-content;
      .logo {
        width: 156px;
        justify-self: center;
      }
    }
    ul {
        padding-left: 20px;
        color: $primaryDarkColor;
        list-style: none;
        display: flex;
        height: 53px;
        align-items: center;
        margin: 0;
        align-items: center;
        @media (max-width: 830px) {
          display: none;
        }
        justify-content: space-around;
        width: 60%;
        min-width: 400px;
        max-width: 622px;
        li span {
          text-transform: capitalize;
          cursor: pointer;
        }
    }
    a, .about-us {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
    }
    span {
      display: flex;
      svg {
        width: 16px;
        padding-left: 4px;
      }
    }
}

.about-menu {
  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    text-transform: none;
    color: #012D6C;
  }
  ul {
    padding: 0;
  }
  li {
    padding: 8px 16px;
    &:focus {
      background-color: inherit;
    }
    &:hover {
      background-color: rgb(79, 173, 242, 0.2);
      a {
        color: #000;
      }
    }
  }
}

.hamburger-wrapper {
  display: none;
  @media (max-width: 830px) {
    display: flex;
  }
  .hamburger {
    display: none !important;
    cursor: pointer;
    @media (max-width: 830px) {
      display: block !important;
      padding-left: 32px;
      color: $primaryDarkColor;
    }
  }
}

.header-dialog {
  box-shadow: none;
  background-color: #FFF;
   .header-dialog-paper {
     background-color: #fff;
    //  .header-wrapper {
    //   background: linear-gradient(
    //     180deg, rgba(79, 173, 242, 0.08) 0%, rgba(79, 173, 242, 0) 100%);
    //       background-size: 100% 30%;
    //       background-repeat: no-repeat;
    //  }
     .header-nav {
        height: 100%;
        width: 80%;
        margin: 0;
        color: $primaryDarkColor;
        list-style: none;
        padding: 0 10%;
        li {
          padding: 8px 0;
        }
        li span {
          font-weight: bold;
          text-transform: capitalize;
          cursor: pointer;
        }
        .user-profile-link {
          display: flex;
          align-items: center;
          .user-avatar {
            margin-right: 8px;
          }
        }
     }
   }
}