@import '../../../sass/base';

.home {
    // background: url('../../assets/gradient-background.png');
    // background-repeat: repeat-x;
    position: relative;
    min-height: calc(100vh - 400px);
    padding-left:10%;
    .go-back {
      display: flex;
      align-items: center;
      svg {
          font-size: 16px;
          padding-right: 8px;
      }
      padding-bottom: 32px;
      cursor: pointer;
    }
    .tabs-parent {
      width:calc(100% - 400px);
      @media (max-width: 830px) {
        width: 90%;
        padding-right: 10%;
        display: block;
      }
      justify-content: center;
      display: grid;
      .MuiBox-root{
        padding:0;
      }
      form {
        width: 416px;
        position: relative;
        @media (max-width: 830px) {
          width: 100%;
        }
        button[type="submit"] {
          margin-bottom: 60px;
        }
      }
      padding:0;
    }
}

.MuiTab-textColorInherit.Mui-selected span.MuiTab-wrapper {
  color: #012D6C;
}
.MuiTab-root.Mui-selected {
  background-color: #ceeef8;
}
.MuiTab-root:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.divider-small {
  margin: 8px 0 !important;
}

.sideNav {
  @media (max-width: 1000px) {
    display: none;
  }
}

.nav-dropdown {
  display: none;
  @media (max-width: 1000px) {
    display: block;
    margin: 0 10% $defaultPadding 0;
  }
}


// .education-modal {
//   h5 {
//     font-weight: 800;
//   }
// }

// .button-wrapper {
//   display: flex;
//   justify-content: space-between;
// }

// .education-dialog {
//   .dialog-title {
//     &-wrapper {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//     }
//     h6 {
//       font-weight: 800;
//     }
//   }
//   .button-wrapper {
//     padding: 50px 0 16px 0;
//   }
//   .dialog-close {
//     position: absolute;
//     right: 4px;
//     top: 4px;
//   }
// }

// .education-summary {
//   display: grid;
//   .education-title {
//     padding-bottom: 0;
//     margin: 8px 0;
//   }
//   .education-item {
//     color: $footerGrey;
//   }
//   .education-item-divider {
//     margin: 16px 0;
//   }
//   .button-wrapper {
//     width: 100%;
//     align-items: center;
//   }
// }

.agent-tile {
  border: 1px solid #dbdbdb;
  height: fit-content;
  width: 300px;
  min-width: 150px;
  padding: $defaultPadding;
  margin-right: 10%;
  border-radius: 10px;
  .button-container {
    button {
      margin-right: $defaultPadding;
    }
  }
  h6 {
    font-weight: bold;
    padding: 16px 0;
  }
  .user-avatar-wrapper {
    .user-avatar, .user-avatar img {
      width: 64px;
      height: 64px;
    }
    .user-avatar {
      border-radius: 50%;
    }
  }
  @media (max-width: 830px) {
    margin-bottom: 100px;
    width: fit-content;
  }
}

.home-content {
  @media (max-width: 830px) {
    display: grid !important;
  }
}
.agent-message, .agent-warning {
  width: auto;
  border: 2px solid $primaryColor;
  padding: $defaultPadding;
  border-radius: 10px;
  margin-bottom: 32px !important;
  text-align: center;
  display: block;
}

.agent-warning {
  border: 2px solid $error;
  display: flex;
  align-items: center;
  .alert-icon {
    color: $error;
    padding: 8px;
  }
}

.hoverClick {
  cursor: pointer;
  color: $primaryColor;
  &:hover {
    color: $primaryColor;
  }
}