@import '../../../sass/base';

.documents-upload {
    min-width: 416px;
    max-width: 416px;
    position: relative;
    h4 {
        font-weight: bold;
        padding-bottom: 16px;
    }
}

