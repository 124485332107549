@import '../../../sass/base';

.manage-application {
  position: relative;
  z-index: 2;
  .go-back {
    display: flex;
    align-items: center;
    svg {
        font-size: 16px;
        padding-right: 8px;
    }
    padding-bottom: 32px;
    cursor: pointer;
  }
    // background: url('../../assets/gradient-background.png');
    // background-repeat: repeat-x;
    padding: 50px 10%;
    .home-agent-buttons {
      button {
        margin-right:30px;
        margin-top:40px;
        margin-bottom:80px;
      }
    }
    .actions-button-container {
      button {
        margin-right: $defaultPadding;
      }
    }
    .user-avatar-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .user-avatar, .user-avatar img {
        width: 42px;
        height: 42px;
      }
      .user-avatar {
        border-radius: 10px;
      }
    }
    .MuiDataGrid-row.Mui-even {
      background-color: #F5F5F5;
      border: 1px solid #F5F5F5;
      border-radius: 10px;
    }
    .MuiDataGrid-row.Mui-odd {
      border: none;
    }
    .MuiDataGrid-root .MuiDataGrid-cell {
      border: unset;
    }
    .MuiDataGrid-root .MuiDataGrid-row:hover {
      background-color: rgba(79, 173, 242, 0.2);
    }
    .invite-table {
      padding-top: 32px;
    }
    .sm-table-label {
      display: flex;
      span {
        color: $footerGrey;
        padding: 0 8px;
      }
      span:first-of-type {
        border-right: 1px solid $footerGrey;
        padding-left: 0;
      }
    }
}
.dialog-title {
  button {
    position: absolute;
    right: 24px;
    top: 16px;
    color: rgba(0, 0, 0, 0.87);
  }
}

.default-image {
  background-color: #b5b5b5;
  padding-right: 2px;
}

.MuiButton-root.Mui-disabled {
  opacity: 0.4;
  color: #fff !important;
}