@import '../../../../sass/base';

.dragDrop-item {
  display: inline-grid;
  color: $footerGrey;
  padding: 8px;
  width: calc(125px - 34px);
  svg {
    margin: auto;
  }
  h5 {
    margin: 8px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    white-space: nowrap;
  }
  .cancel-icon {
    font-size: 0.8em;
    cursor: pointer;
  }
  div:first-of-type {
    margin:auto;
    cursor: pointer;
  }
} 