@import '../../../sass/base';

.progress {
    padding-left: 10%;
    padding-bottom: 100px;
    .go-back {
        display: flex;
        align-items: center;
        svg {
            font-size: 16px;
            padding-right: 8px;
        }
        padding-bottom: 32px;
        cursor: pointer;
    }
    h4 {
        font-weight: 600;
        padding-bottom: 32px;
    }
    .appliedTo {
        padding-bottom: 16px;
    }
    .progress-timeline {
        max-width: 416px;
        position: relative;
        z-index: 2;
        .MuiTimelineItem-missingOppositeContent:before {
            display: none;
        }
        .MuiTimelineDot-root {
            height: 12px;
            width: 12px;
            .MuiSvgIcon-root {
                font-size: 0.8em;
            }
        }
        .MuiTimelineContent-root {
            padding-top: 0;
            padding-bottom: 32px;
        }
        // .MuiTimelineDot-outlinedGrey {
        //     border: 5px solid #B5B5B5;
        //     height: 13px;
        //     width: 13px;
        // }
    }
    .item-disabled {
        color: $footerGrey !important;
    }
}